import Helper from '@/services/helper';

export default {
    async getLandingContent() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/network/get-landing-content');
        
        return result;
    },
    async getNetworkDetail(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/network/get-detail', param);
        
        return result;
    },
    async getStoreList(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/network/get-store-list', param);
        
        return result;
    },
    async getProductList(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/network/get-product-list', param);
        
        return result;
    },
    async getSingleProduct(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/network/single-product', param);
        
        return result;
    },
    async getNetworkList() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/network/get-network-list');
        
        return result;
    }
}