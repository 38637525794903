<template>
    <section class="networks">
        <router-link :to="routerPath.NETWORK + '/' + item.param" v-for="(item, index) in networks" :key="index" :title="item.title">
            <img :src="item.img" :alt="item.title" :title="item.title" />
            <span class="d-none d-md-block">
                {{ item.title }}
            </span>
        </router-link>
    </section>
</template>

<script>
import RouterPath from '@/router/path';

export default {
    data() {
        return {
            routerPath: RouterPath
        };
    },
    props: {
        networks: Array
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.networks {
    @include gridContentWrapper(0 -10px);

    @media only screen and (max-width: $screenExtraSmall) {
        @include gridContentWrapper(0 -3px);
    }

    a {
        display: block;
        padding: 0 10px 10px;
        width: 16.6667%;

        @media only screen and (max-width: $screenSmall) {
            width: 20%;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 33.3333%;
            padding: 0 3px 6px;
        }

        img {
            display: block;
            width: 100%;
            border: 1px solid #555;
        }

        span {
            display: block;
            font-size: 14px;
            color: #000;
        }
    }
}
</style>